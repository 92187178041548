import React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import {Container, Row, Col, ListGroup} from 'react-bootstrap'
import  '../css/common.css'
import {Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,faTwitter,faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'


const Footer = () => {
    return (
       
        <div className="footer" >
             <hr className="hrline" />
        <Container className="footer">
            <Row>
                <Col sm={12} lg={3}>
                    <ListGroup><span className="footerTitle"> HARDWARE DEVELOPMENT</span>
                    <Link to="/home-automation"><ListGroup.Item className="footerList" >Home Automation</ListGroup.Item></Link>
                    <Link to="/design-for-manufacturing"><ListGroup.Item className="footerList">Design for Manufacturing</ListGroup.Item></Link>
                    <Link to="/mechanical-design"><ListGroup.Item className="footerList">Mechanical & CAD Design</ListGroup.Item></Link>
                    <Link to="/electrical-design"><ListGroup.Item className="footerList">Electrical Design & Circuit Board Design</ListGroup.Item></Link>
                    <Link to="/prototype-design"><ListGroup.Item className="footerList">Prototype Design</ListGroup.Item></Link>
                    </ListGroup>
                </Col>
                <Col sm={12} lg={3}>
                    <ListGroup><span className="footerTitle">SOFTWARE DEVELOPMENT</span>
                    <Link to="/cloud-solutions"><ListGroup.Item className="footerList">Cloud Solutions</ListGroup.Item></Link>
                    <Link to="/erp-software"><ListGroup.Item className="footerList">ERP Software</ListGroup.Item></Link>
                    <Link to="/crm-software"><ListGroup.Item className="footerList">CRM Software</ListGroup.Item></Link>
                    <Link to="/mobile-desktop"><ListGroup.Item className="footerList">Mobile & Desktop</ListGroup.Item></Link>
                    <Link to="/embedded-software"><ListGroup.Item className="footerList">Embedded Software</ListGroup.Item></Link>
                    </ListGroup>
                </Col>
                <Col sm={12} lg={3}>
                    <ListGroup><span className="footerTitle">COMPANY</span>
                        <Link to="/free-project-evaluation"><ListGroup.Item className="footerList">Free Project Evaluation</ListGroup.Item></Link>
                        <Link to="/make-contact"><ListGroup.Item className="footerList">Make Contact</ListGroup.Item></Link>
                        <Link to="/who-we-are"><ListGroup.Item className="footerList">Who We Are</ListGroup.Item></Link>
                        {/* <Link href="/blog"><ListGroup.Item className="footerList">Blog</ListGroup.Item></Link> */}
                    </ListGroup>
                </Col>
                <Col sm={12} lg={3}>
                <br/>
                   <span className="footerTitle">CONNECT WITH US</span><br/><br/>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/TraceWidthTechnologySolutions"><FontAwesomeIcon aria-label="Facebook"  icon={faFacebook} size="2x" className="footerIcon"  /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/tracewidthtech"><FontAwesomeIcon aria-label="Twitter" icon={faTwitter} size="2x" className="footerIcon" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/trace-width-technology-solutions-llc-"><FontAwesomeIcon aria-label="Linkedin" icon={faLinkedin} size="2x" className="footerIcon" /></a>
                    <br/><br/>
                </Col>
              
            </Row>
            <hr className="footerhr"/>
            <span className="float-right uparrow " onClick={() => window.scrollTo(0, 0)}><FontAwesomeIcon aria-label="Twitter" icon={faAngleUp} size="1x" className="upicon" /></span>
            <span className="footerSpan">© Copyright {new Date().getFullYear()},  Trace Width Technology Solutions | Privacy</span>
            
        </Container>
    </div>
        // <div>
        //    
        // </div>
    )
}

export default Footer