import PropTypes from "prop-types"
import React from "react"
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Button, NavDropdown, Navbar} from 'react-bootstrap'
import '../css/common.css'
import { Link } from 'gatsby'
import Image from "../components/image"

config.autoAddCss = false;

const Header = ({ siteTitle }) => {
  return (
    <header id="herdRo">
      <Navbar bg="light">
        <Nav className="ml-auto navBarTop">
          <Nav.Item as="li" >
            <Nav className="callAndMail"><FontAwesomeIcon icon={faPhone} className="iconC" /> <span style={{ margin: '2px'}}> CALL US: (727) 656-5030</span></Nav>
          </Nav.Item>
          <span className="middleBar"></span>
          <Nav.Item as="li">
            <Nav className="callAndMail"><FontAwesomeIcon icon={faEnvelope} className="iconC" /><span style={{ margin: '2px'}}>SALES@TRACEWIDTH.COM</span></Nav>
          </Nav.Item>
        </Nav>
      </Navbar>
      <Navbar bg="light" expand="lg">
        <Link to="/" className="logo"><Image alt="logo" filename="logo.png" /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto NavMenu">
              <NavDropdown title="HARDWARE">
                <NavDropdown.Item href="/hardware">Hardware Design</NavDropdown.Item>
                <NavDropdown.Item href="/home-automation" >Home Automation</NavDropdown.Item>
                <NavDropdown.Item href="/design-for-manufacturing">Manufacturing Design</NavDropdown.Item>
                <NavDropdown.Item href="/mechanical-design">Mechanical Design</NavDropdown.Item>
                <NavDropdown.Item href="/electrical-design">Electrical Design</NavDropdown.Item>
                <NavDropdown.Item href="/prototype-design">Prototype Design</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="SOFTWARE" >
                <NavDropdown.Item href="/software">Software Design</NavDropdown.Item>
                <NavDropdown.Item href="/mobile-desktop" >Mobile & Desktop</NavDropdown.Item>
                <NavDropdown.Item href="/cloud-solutions" >Cloud Solutions</NavDropdown.Item>
                <NavDropdown.Item href="/embedded-software">Embedded Software</NavDropdown.Item>
                <NavDropdown.Item href="/crm-software">CRM Software</NavDropdown.Item>
                <NavDropdown.Item href="/erp-software">ERP Software</NavDropdown.Item>
              </NavDropdown>
              <Nav.Item as="li">
                <Nav.Link href="/who-we-are">WHO WE ARE</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        <Nav className="NavMenu">
          <Nav.Item as="li">
            <Nav.Link href="/free-project-evaluation"><Button className="navBtn">FREE PROJECT EVALUATION</Button></Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar>
    </header>

  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
